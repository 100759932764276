<template>
  <div>
    <div>
      <van-cell-group>
        <van-field
          v-model="list"
          label="采集区域:"
          placeholder="请选择采集区域"
        />
        <van-field v-model="value" label="试管码:" placeholder="识别条形码" />
        <van-field v-model="value" label="登记数:" placeholder="已登记数量" />
      </van-cell-group>
    </div>
    <div v-if="info">
      <van-field
        v-model="dataFrom.name"
        label="姓名:"
        placeholder="请输入姓名"
      />
      <van-field
        v-model="dataFrom.idcard"
        label="身份证号:"
        placeholder="请输入身份证号"
      />
      <van-field
        v-model="dataFrom.address"
        label="住址:"
        placeholder="请输入住址"
      />
      <van-field
        v-model="dataFrom.phone"
        label="联系电话:"
        placeholder="请输入联系电话"
      />
      <div>
        <span
          >是否发热:<van-radio-group
            v-model="dataFrom.IsFever"
            direction="horizontal"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group></span
        >
      </div>
      <div>
        <span
          >是否去过高风险地区:<van-radio-group
            v-model="dataFrom.IsFromRisk"
            direction="horizontal"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="2">否</van-radio>
          </van-radio-group></span
        >
      </div>
      <div>
        <span
          >检验类型:<van-radio-group
            v-model="dataFrom.CheckSort"
            direction="horizontal"
          >
            <van-radio name="1">现场</van-radio>
            <van-radio name="2">急诊</van-radio>
            <van-radio name="3">住院</van-radio>
            <van-radio name="4">陪护</van-radio>
          </van-radio-group></span
        >
      </div>
      <div><span>备注:</span></div>
      <div>
        <van-radio v-model="dataFrom.zhuangtai" name="1"></van-radio
        >请确认以上信息准确无误
      </div>
      <div>
        <button>扫描身份证</button>
        <button>提交</button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { GetMyCollecAreaList, } from "@/api/dayao";
export default {
  data() {
    return {
      info: true,
      dataFrom: {
        name: undefined,
        idcard: undefined,
        address: undefined,
        phone: undefined,
        IsFever: 1,
        IsFromRisk: 1,
        CheckSort: 1,
        Remark: undefined,
        zhuangtai: undefined,
      },
      list:{},
    };
  },
  created(){
      this.getList();
  },
  methods: {
    getList() {
        GetMyCollecAreaList().then((res)=>{
            console.log(res);
            this.list = res.data.data;
            console.log(this.list);
        })
    },
    fromc() {
      if (this.datafrom.certNumber == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.certNumber == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.certNumber == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
      if (this.datafrom.certNumber == "") {
        Toast.fail("请输入身份证号");
        return false;
      }
    },
  },
};
</script>
<style scoped>
</style>